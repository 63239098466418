<template>
  <div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>
        <!-- 标题 -->
        <title-enzh enTitle="PRODUCT PRICE" zhTitle="产品定价" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
        <div class="main x_between">
            <div style="flex:1.3">
                <img src="@/assets/image/customDev/main_img.png" class="main_img">
            </div>
<!--            <div style="flex:3" class="price_img">-->
<!--                <img src="@/assets/image/productprice/price.png" class="price_img">-->
<!--                <div v-for="(item,index) in rows" :key="index" class="row x_between_y_center">-->
<!--                    <div style="flex:2" class="y_center">-->
<!--                        <img :src="item.icon" class="row_icon">-->
<!--                        <div class="title" v-text="item.title"></div>-->
<!--                    </div>-->
<!--                    <div style="flex:4.7" class="describe" v-text="item.describe"></div>-->
<!--                </div>-->
            <div class="my_table_class" style="width:62%;position: absolute;right: 4%;top:20%;color: #D7D7D7">
                <el-table
                        :cell-style="{'text-align':'center','borderColor':'#D7D7D7','font-size': '20px','color': 'white'}" :data="tableData":span-method="objectSpanMethod"
                        :header-cell-style="{'background-color': '#3f5c6d2c','text-align':'center','borderColor':'#D7D7D7','font-size': '20px','color': 'white'}"
                        border
                        :stripe="false"
                        :row-class-name="tableRowClassName"
                        style=""
                >
                    <el-table-column label="产品名称" prop="name" align="center">
                    </el-table-column>
                    <el-table-column label="授权方式" align="center">
                        <el-table-column label="年租授权" align="center">
                            <el-table-column label="首年" prop="first" align="center">
                            </el-table-column>
                            <el-table-column label="续费" prop="second" align="center">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="永久授权" prop="forever" align="center">
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div class="bottom_text">
                <span class="lable" style="white-space: pre-wrap;">{{markmsg}}</span>
            </div>
        </div>
    </div> 
  </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
export default {
    name: 'twoPage',
    data(){
        return {

            markmsg:"备注：\n1、BCF云协同本地部署需要客户自备服务器和外网ip，数据将全部存储在客户服务器。" +
                "\n2、BIMOM构件库(企业版)本地部署需要客户自备服务器和外网ip，数据将全部存储在客户服务器。" +
                "\n3、订购20套以上BIMOM铝模设计产品赠送企业铝模参数化图库定制。" +
                "\n4、正版用户从盈建科合作渠道购买'ACurRebar曲率配筋'、'BIMOM建筑工具'有优惠。",
            spanArr: [],
            pos: 0,
            tableData: [
                {
                    index:1,
                    name:"ACurRebar",
                    first:"/",
                    second:"/",
                    forever:"8999¥/点位",
                },
                {
                    index:2,
                    name:"BIMOM建筑工具",
                    first:"/",
                    second:"/",
                    forever:"4800¥/点位",
                },
                {
                    index:3,
                    name:"BIMOM BCF协同",
                    first:"988¥/点位/年",
                    second:"688¥/点位/年",
                    forever:"/",
                },
                {
                    index:4,
                    name:"BIMOM BCF系统(企业本地部署)",
                    first:"2488¥/点位/年",
                    second:"1688¥/点位/年",
                    forever:"24888¥/点位",
                },
                {
                    index:5,
                    name:"BIMOM 铝模设计",
                    first:"3999¥/点位/年",
                    second:"1999¥/点位/年",
                    forever:"16999¥/点位",
                },
                {
                    index:6,
                    name:"BIMOM 构件库(企业版)",
                    first:"1688¥/点位/年",
                    second:"1288¥/点位/年",
                    forever:"5888/点位",
                },
                {
                    index:7,
                    name:"插件定制开发",
                    first:"根据企业定制功能开发需求评估",
                    second:"",
                    forever:"",
                },
                {
                    index:8,
                    name:"平台定制开发",
                    first:"根据企业定制功能开发需求评估",
                    second:"",
                    forever:"",
                },
            ],
        }
    },
    mounted(){
        this.getprice();
        this.getSpanArr();
    },
    methods:{
        tableRowClassName({ row, rowIndex }) {
            if ((rowIndex + 1) % 2 === 0) {
                return "background:rgba(160,188,231,0.9)";
            } else {
                return "background:rgba(0,8,1,0.9)";
            }
        },

        backHome(){
            this.$router.push("/")
        },
        getSpanArr(data) {
            this.spanArr = [];
            if(data) {
                for (let i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1);
                        this.pos = 0;
                    } else {
                        if (data[i].first === data[i - 1].second) {   //相同班级合并
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            }
        },
        objectSpanMethod({ rowIndex, columnIndex }) {
            //合并第一列
            // if (columnIndex === 0) {
            //     const _row = this.spanArr[rowIndex];
            //     const _col = _row > 0 ? 1 : 0;
            //     return {
            //         rowspan: _row,
            //         colspan: _col
            //     }
            // }
            if (this.tableData[rowIndex].forever==="/"&columnIndex===1) {
                return [1, 3];
            }
            // if (rowIndex === 7&columnIndex===1) {
            //     return [1, 3];
            // }
        },
        getprice(){
            let config = {
                headers : {
                    'Content-Type':'application/json'
                }
            };
            const param={
                title:this.title,
            };
            this.axios.post(this.VUE_APP_BASE_API+'/Product/getprice',param,config).then((res)=>{
                if(res.data.resultCode==0){
                    this.tableData=[];
                    res.data.resultData.forEach((val)=>{
                        let data={
                            index:val.index,
                            name:val.product_name,
                            first:val.product_first===0?(val.product_forever===0?"根据企业定制功能开发需求评估":"/"):(val.product_first+"¥/点位/年"),
                            second:val.product_second===0?"/":(val.product_second+"¥/点位/年"),
                            forever:val.product_forever===0?"/":(val.product_forever+"¥/点位"),
                        }
                        this.tableData.push(data);
                    });
                    this.tableData.sort(function(a,b){return a.index -b.index});
                    console.log(this.tableData);

                }else{
                    alert(res.data.resultMsg);
                }
            });
        },
    },
    components:{
        topBanner,
        titleEnzh
    }
}
</script>

<style lang="scss" scoped>

.bgm{
  width: 100%;
  height: 100%;
  background: url("../assets/image/customDev/custom_dev_-bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px!important;
  }
}
.main{
    margin-top: 40px;
    .main_img{
        width: 40%;
        height: 88vh;
        margin-left: -100px;
        margin-top: -80px;
    }
    .price_img{
        width: 100%;
        height:50%;// 88vh;
        margin-left: 10px;
        margin-top: 0px;
    }
}

.row{
    margin-bottom: 60px;
    padding-left: 30px;
    font-family: '幼圆', sans-serif;
    font-size: 28px;
    .row_icon{
        width:56px;
        margin-right: 20px;
    }
    .title{
        font-size: 20px;
        font-family: "Agency FB Normal", "Agency FB", sans-serif;
        color:#fff;
    }
    .describe{
        font-size: 20px;
        color:#797979
    }
}
.bottom_box{
    position: absolute;
    bottom: 5%;
    right: 4%;
    font-family: 'Arial Normal', 'Arial', sans-serif;
    color:#D7D7D7;
    font-size: 16px;
    width: 62%;
}
.bottom_text{
    /*margin-top: 50px;*/
    position: absolute;
    margin-top: 550px;
    right: 4%;
    font-family: 'Arial Normal', 'Arial', sans-serif;
    color:#D7D7D7;
    font-size: 16px;
    width: 62%;
}

</style>

<style scoped>
    .my_table_class >>> .el-table, .el-table__expanded-cell {
        background-color: rgba(43,57,66,0.21);
    }
    .my_table_class >>> .el-table tr {
        background-color:rgba(43,57,66,0.21);
    }
    .my_table_class >>> .el-table--enable-row-transition .el-table__body td, .el-table .cell{
        background-color: rgba(43,57,66,0.21);
    }
    .my_table_class >>> .el-table tbody tr:hover>td {
        background-color:#ffffff!important
    }
    .my_table_class >>> .el-table tbody tr {
        pointer-events:none;
    }
    .el-table::before {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0px;
    }
</style>